import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { translations, getTopicName, getInstructionVideo } from './Utilities';
import { useLanguage } from './LanguageProvider';
import { useUserAnswers } from '../Practice/UserAnswers';
import { useRecordUser } from './RecordUser';
import DataContext from './DataContext';

const BurgerMenuComponent = () => {
  let navigate = useNavigate();
  const [isBurgerMenuClicked, setIsBurgerMenuClicked] = useState(false);
  const { selectedLanguage } = useLanguage();
  const {  fetchSearchLevel, allTopics, fetchAllTopics, sentencesForStudy, setSentencesForStudy } = useContext(DataContext);
  const {updateTopic, updateEnglishTopic, updateLevel } = useUserAnswers();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [filteredTopicResults, setFilteredTopicResults] = useState([]);
  const {closeAudio} = useRecordUser(); 
  // const [themeClick, setThemeClick] = useState(false);
  const [topicClick, setTopicClick] = useState(false);

  useEffect(() => {
    fetchAllTopics();
  }, []);

  // useEffect(() => {    
  //   if (themeClick && topics.length === 0){
  //     setThemeClick(false);
  //     navigate('/topic-selection');
  //   }
  // }, [themeClick]);

  useEffect(() => {    
    if (topicClick && sentencesForStudy.length === 0){
      setTopicClick(false);
      navigate('/speaking-sentences', { state: { index: 0 } });
    }
  }, [topicClick]);

  const handleBurgerMenuClose = () => {
    setIsBurgerMenuClicked(false);
  };

  const goToHome = () => {
    closeAudio();
    setIsBurgerMenuClicked(false);
    navigate('/');
  };
  const goToMyVocab = () => {
    setIsBurgerMenuClicked(false);
    navigate('/my-vocabulary');
  };
  const goToGrammar = () => {
    setIsBurgerMenuClicked(false);
    navigate('/grammar');
  };
  const goToPronunciation= () => {
    setIsBurgerMenuClicked(false);
    navigate('/pronunciation');
  };
      
  const handleSearchChange = (e) => {   
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
  
    if (term) {
      const results = new Set(); // Use Set to avoid duplicates
      // themes.forEach((theme) => {
      //   if (getThemeName(theme, selectedLanguage).toLowerCase().includes(term)) {
      //     let result = theme;
      //     result.type = 'theme';
      //     results.add(result);
      //   }
      // });
      allTopics.forEach((topic) => {
        if (getTopicName(topic, selectedLanguage).toLowerCase().includes(term)) {
          let result = topic;
          result.type = 'topic';
          results.add(result);
        }
      });      
      setFilteredResults(Array.from(results));
    } else {
      setFilteredResults([]);
      setFilteredTopicResults([]);
    }
  };  
  
  const handleResultClick = async (result) => {   
    // if (result.type === 'theme'){      
    //   updateTheme(getThemeName(result, selectedLanguage));
    //   updateEnglishTheme(getThemeName(result, 'en'));   
    //   setTopics([]);  
    //   setSearchTerm('');
    //   setThemeClick(true); 
    // } else {
      // let themeFound = await fetchSearchTheme(getTopicName(result, 'en'));
      // if (Array.isArray(themeFound) && themeFound.length > 0 && englishTopic !== getTopicName(result, 'en')) {
        // updateTheme(getThemeName(themeFound[0], selectedLanguage));
        // updateEnglishTheme(getThemeName(themeFound[0], 'en'));
    let levelFound = await fetchSearchLevel(getTopicName(result, 'en'));
    if (levelFound > 0) {
      updateLevel(levelFound);
      setSentencesForStudy([]);
      updateTopic(getTopicName(result, selectedLanguage));
      updateEnglishTopic(getTopicName(result, 'en'));
      setSearchTerm('');
      setIsBurgerMenuClicked(false);  
      setTopicClick(true);
     }          
    // }       
  };

  /********** to close searchbar **********/  
  const searchbarRef = useRef(null);
  const handleClickOutside = (event) => {
    if (searchbarRef.current && !searchbarRef.current.contains(event.target)) {
      setSearchTerm('');
      setFilteredResults([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  
  /********** instruction video **********/  
  const [showVideo, setShowVideo] = useState(false);

  const handleVideoInstructionClick = () => {
    setShowVideo(true);
  };

  const handleCloseVideo = () => {
    setShowVideo(false);
  };

  return (
    <>
      <button className="burger-menu-icon" onClick={() => setIsBurgerMenuClicked(true)}>
        <img src={`/Icons/burgermenu.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].Menu} />
      </button>
      {isBurgerMenuClicked && (
        <div className="popup-burgermenu">
          <button className="x-button" onClick={handleBurgerMenuClose}><b>X</b></button>

          <div className="search-bar" ref={searchbarRef}>
          <input type="text" value={searchTerm} onChange={handleSearchChange} 
              placeholder={translations[selectedLanguage].SearchATopic} />
          <button className="search-icon-button">
              <img src={`/Icons/search.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].Search} />
          </button>
          </div>

          {(filteredResults.length > 0 || filteredTopicResults.length > 0) && (
              <div className="search-results" ref={searchbarRef}>
              {filteredResults.map((result, index) => (
                  <button
                  key={index}
                  className="search-result-button"
                  onClick={() => handleResultClick(result)}
              >
                  {getTopicName(result, selectedLanguage)}
                  {/* {result.type === 'theme' ? getThemeName(result, selectedLanguage) : getTopicName(result, selectedLanguage)}                     */}
              </button>
              ))}                
              </div>
          )}
        
          <div className="menu-buttons">
            <button className="menu-button" onClick={() => goToHome()}>{translations[selectedLanguage].Home}</button>
            <button className="menu-button" onClick={handleVideoInstructionClick}>{translations[selectedLanguage].InstructionVideo}</button> 
            <button className="menu-button" onClick={() => goToMyVocab()}>{translations[selectedLanguage].MyVocab}</button>
            <button className="menu-button" onClick={() => goToGrammar()}>{translations[selectedLanguage].Grammar}</button>
            <button className="menu-button" onClick={() => goToPronunciation()}>{translations[selectedLanguage].Pronunciation}</button>   
            {/* <button className="menu-button" onClick={() => navigate('/profile')}>{translations[selectedLanguage].Profile}</button> */}
          </div>

          {showVideo && (
            <div className="video-popup-overlay">
              <button className="video-close-button" onClick={handleCloseVideo}>X</button>
              <video controls className="responsive-video">
              <source src={getInstructionVideo(selectedLanguage)} type="video/mp4" />
                {translations[selectedLanguage].Doesnotsupportvideo}
              </video>
            </div>
          )}

        </div>
      )}
    </>
  );
};

export default BurgerMenuComponent;