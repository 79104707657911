import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DataContext from '../Reusables/DataContext';
import { usePlayAudio } from '../Reusables/PlayAudio';
import { translations, getInstruction } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import '../Learn/Learn.css';

const PronunciationContent = () => {
  const location = useLocation();
  const { topicId } = location.state || { topicId: -1};  
  const { topicName } = location.state || { topicName: ''};
  const { groupId } = location.state || { groupId: -1};  
  const { groupName } = location.state || { groupName: ''};
  const { pronunciationContent, fetchPronunciationContentFromGroup, fetchPronunciationContentFromTopic } = useContext(DataContext);
  const {playPause} = usePlayAudio();
  const { selectedLanguage } = useLanguage();
  const navigate = useNavigate();

  useEffect(() => {    
    if(groupId > -1){
      fetchPronunciationContentFromGroup(groupId, selectedLanguage);
    } else if (topicId > -1){
      fetchPronunciationContentFromTopic(topicId, selectedLanguage);
    }
    
  }, [topicId, groupId]);

  useEffect(() => {
    const buttons = document.querySelectorAll('.listen-button');
    
    const handlePlayPause = (event) => {
      const audio = event.currentTarget.getAttribute('data-audio');
      playPause(audio);      
    };

    buttons.forEach(button => {
      button.addEventListener('click', handlePlayPause);
    });
  
    // Cleanup: Remove event listeners when component unmounts or re-renders
    // return () => {
    //   buttons.forEach(button => {
    //     button.removeEventListener('click', handlePlayPause);
    //   });
    // };
  }, [pronunciationContent]);

const content = ``;

  // WHEN JAVASCRIPT CHANGES TO HTML
//   const processedContent = pronunciationContent
//     ? pronunciationContent.replace(
//         /style={{ marginBottom }}/g,
//         `style="margin-bottom: ${marginBottom} !important"`
//       ).replace(
//         /style={{ transform: `scale\(${buttonScale}\)` }}/g,
//         `style="transform: scale(${buttonScale})"`
//       ).replace(/className=/g, 'class=')
//     : '';

  return (   
    <div className='grammar-page'>
      <div className="top-row">
        <button onClick={() => navigate(-1)} className="back-button"> <img src={`/Icons/arrow-left.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].back} />  </button>
        {topicId > 0 ? 
        <h3>{topicName}</h3> : <h3>{groupName}</h3>} 
        <div><BurgerMenuComponent/></div>  
      </div>       
      <div className='grammar-content'>         
        <br/> <br/>
        <div dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} dangerouslySetInnerHTML={{ __html: pronunciationContent }} /> 
      </div>        
      {/* <div dangerouslySetInnerHTML={{ __html: content }} />       */}
    </div>    
  );
};

export default PronunciationContent;
