import React, {useContext, useState} from 'react';
import { useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserAnswers } from '../Practice/UserAnswers';
import { translations, getTopicName } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import './TopicSelection.css'; 
import DataContext from '../Reusables/DataContext';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';

const TopicSelection = () => {
  let navigate = useNavigate();
  const { level, updateTopic, updateEnglishTopic} = useUserAnswers();
  const { topics, fetchTopics, sentencesForStudy, setSentencesForStudy, setConversations} = useContext(DataContext);
  const { selectedLanguage } = useLanguage();
  // const [expandedTopicIndex, setExpandedTopicIndex] = useState(null); 
  const [clickStudy, setClickStudy] = useState(false);
  // const [clickSummary, setClickSummary] = useState(false);
  // const [clickPractice, setClickPractice] = useState(false);
  
  useEffect(() => {    
    fetchTopics(selectedLanguage, level);
  }, []);
  
  // const handleTopicClick = (index) => {
  //   setExpandedTopicIndex(index === expandedTopicIndex ? null : index); // Toggle the expanded state
  // };

  useEffect(() => {    
    if (clickStudy && sentencesForStudy.length === 0){
      setClickStudy(false);
      navigate('/speaking-sentences', { state: { index: 0 } });
    }
  }, [clickStudy]);

  const handleClickStudy = (topicName, topicNameEn) => {    
    setSentencesForStudy([]);
    setConversations([]);
    updateTopic(topicName);
    updateEnglishTopic(topicNameEn);
    setClickStudy(true);    
  };

  // useEffect(() => {    
  //   if (clickSummary && sentencesForStudy.length === 0){
  //     navigate('/summary-page'); 
  //   }
  // }, [clickSummary]);

  // const handleClickSummary = (topicName, topicNameEn) => {    
  //   setSentencesForStudy([]);
  //   setConversations([]);
  //   updateTopic(topicName);
  //   updateEnglishTopic(topicNameEn);
  //   setClickSummary(true);    
  // };

  // useEffect(() => {    
  //   if (clickPractice && sentencesForStudy.length === 0){
  //     navigate('/practice');
  //   }
  // }, [clickPractice]);

  // const handleClickPractice = (topicName, topicNameEn) => {    
  //   setSentencesForStudy([]);
  //   updateTopic(topicName);
  //   updateEnglishTopic(topicNameEn);
  //   setClickPractice(true);    
  // };

  const location = useLocation();  
  const {  fromCompletionPage } = location.state || { fromCompletionPage: false};  
  const goBack = () => {        
    if (fromCompletionPage) { 
      navigate('/completion-page'); 
    } else {      
      navigate('/');      
    }
  };

  return (    
    <div className="topic-container">
      {level !== 0 ? (
         <div>
        <div className="top-row">
          <button onClick={() => goBack()} className="back-button"> <img src={`/Icons/arrow-left.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].back} />  </button>
          <h3>{translations[selectedLanguage].chooseATopic}</h3>
          <div><BurgerMenuComponent /></div>  
        </div> 
    
        <div>
          {topics.map((topic, index) => (
              <div key={topic.id || getTopicName(topic, selectedLanguage)}  className="topics">
              <button className="topic-button" onClick={() => handleClickStudy(getTopicName(topic, selectedLanguage), getTopicName(topic, 'en'))} >
                <div style={{marginRight:"auto"}}>{getTopicName(topic, selectedLanguage)} </div>
                <div style={{marginLeft:"auto"}}><img src={`/Icons/arrow-right.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].next} /></div>
              </button>  

              {/* <button className="topic-button" onClick={() => handleTopicClick(index)} >
                <div style={{marginRight:"auto"}}>{getTopicName(topic, selectedLanguage)} </div>
                <div style={{marginLeft:"auto"}}>{expandedTopicIndex === index ? ( 
                  <img src={`/Icons/arrow-up.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].collapse} />
                ) : (
                  <img src={`/Icons/arrow-down.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].expand} />
                )}</div>
              </button>   */}
              {/* {expandedTopicIndex === index && ( 
                <div className="topic-options">
                  <button
                    className="topic-option"
                    onClick={() => handleClickStudy(getTopicName(topic, selectedLanguage), getTopicName(topic, 'en'))}
                  >
                    <img style={{width:'25px', height:'25px'}} src={`/Icons/study.svg?${new Date().getTime()}`} alt="" /> 
                    <span>  {translations[selectedLanguage].study}</span>
                    <img src={`/Icons/arrow-right.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].next} className="arrow-right" />
                  </button>
                  <button
                    className="topic-option"
                    onClick={() => handleClickSummary(getTopicName(topic, selectedLanguage), getTopicName(topic, 'en'))}
                  >
                    <img style={{width:'20px', height:'20px'}} src={`/Icons/summary.svg?${new Date().getTime()}`} alt="" /> 
                    &nbsp;<span>{translations[selectedLanguage].summary}</span>
                    <img src={`/Icons/arrow-right.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].next} className="arrow-right" />
                  </button>
                  <button
                    className="topic-option"
                    onClick={() => handleClickPractice(getTopicName(topic, selectedLanguage), getTopicName(topic, 'en'))}
                  >
                    <img style={{width:'22px', height:'25px'}} src={`/Icons/practice.svg?${new Date().getTime()}`} alt="" /> 
                    &nbsp;<span>{translations[selectedLanguage].Practice}</span>
                    <img src={`/Icons/arrow-right.svg?${new Date().getTime()}`}alt={translations[selectedLanguage].next} className="arrow-right" />
                  </button>
                </div>
              )} */}
  
              {index < topics.length - 1 && <hr />}
              </div>            
          ))}
        </div>  
        </div>      
        ) : (
          <button className="topic-option" onClick={() => navigate('/')}>{translations[selectedLanguage].PleaseChooseALevel}</button>        
        )} 
    </div>
  );
}

export default TopicSelection;