import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFavorites } from './FavoriteVocab';
import DataContext from '../Reusables/DataContext';
import { playWordAudio, translations, getTranslatedSentence, showPartOfSpeech } from '../Reusables/Utilities';
import { usePlayAudio } from '../Reusables/PlayAudio';
import { useLanguage } from '../Reusables/LanguageProvider';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';

const MyVocabularies = () => {
  let navigate = useNavigate();
  const { toggleFavorite, favorites } = useFavorites(); // Use only fr values from favorites
  const { myVocab, fetchMyVocab } = useContext(DataContext); // fetchMyVocab to get full vocab info from the server
  const { selectedLanguage } = useLanguage();
  const [favoriteVocabData, setFavoriteVocabData] = useState([]);
  const {play} = usePlayAudio(); 

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem('favorites')) || {};
    const favoriteFrArray = Object.keys(storedFavorites); // Retrieve only fr values from localStorage
    
    if (favoriteFrArray.length > 0) {
      fetchMyVocab(favoriteFrArray);
    }else {
      setFavoriteVocabData([]); 
    }
  }, [favorites]);

  useEffect(() => {
    // Ensure myVocab is always treated as an array (even if empty)
    if (myVocab && Object.keys(myVocab).length > 0) {
      setFavoriteVocabData(Object.values(myVocab)); // Convert myVocab object to array
    } else {
      setFavoriteVocabData([]); // Set to an empty array if no data
    }
  }, [myVocab]);
    
  const removeFavorites = (word) => {
    // Remove the favorite from the set and localStorage
    toggleFavorite({ fr: word, en: '' }); // en is not relevant for removal
  };

  // Safeguard in case favoriteVocabData is empty or undefined
  if (!Array.isArray(favoriteVocabData)) {
    setFavoriteVocabData([]);
  }

  return (
    <div className="study-page">
      <div className="top-row">
        <button onClick={() => navigate(-1)} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>        
        <h3>{translations[selectedLanguage].MyVocab}</h3>
        <div><BurgerMenuComponent/></div>          
      </div>
      <div className="my-vocabularies-list">
        {
        // favoriteVocabData && favoriteVocabData.length > 0 ? (
          favoriteVocabData.map((vocab, index) => (
            <div key={index} className="vocab-item">
              <span className="french-word">{vocab.fr}</span>
              <span className="ipa">{vocab.ipa}</span>
              <button className="audio-button" onClick={() => playWordAudio(vocab.fr, play, '')}>
                <img src="Icons/listenDictionary.svg" alt={translations[selectedLanguage].Listen} />
              </button>
              <button style={{transform: 'translateY(2px)'}} className="audio-button" onClick={() => removeFavorites(vocab.fr)}>
                <img src="Icons/trash.svg" alt={translations[selectedLanguage].Delete} />
              </button>
              <br/>       
              <div  dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'}>   
              <span>[{showPartOfSpeech(vocab.part_of_speech_id, selectedLanguage)}]</span>
              <br/> 
              {vocab.contraction && vocab.contraction.trim() !== '' && (
                <span>{translations[selectedLanguage].Contraction}: {vocab.contraction}<br/></span>
              )}
              {vocab.masculine && vocab.masculine.trim() !== '' && (
                <span>{translations[selectedLanguage].Masculine}: {vocab.masculine}<br/></span>
              )}
              {vocab.feminine && vocab.feminine.trim() !== '' && (
                <span>{translations[selectedLanguage].Feminine}: {vocab.feminine}<br/></span>
              )}
              {vocab.plural && vocab.plural.trim() !== '' && (
                <span>{translations[selectedLanguage].Plural}: {vocab.plural}<br/></span>
              )}  
              <br />
              <span className="french-word">{getTranslatedSentence(vocab, selectedLanguage)}</span>
              </div>
              <br/>
            </div>
          ))
        // ) : (
        //   <div>No vocabularies added yet.</div>
        // )
        }
      </div> 
    </div>
  );
};

export default MyVocabularies;
