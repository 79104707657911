import React, { createContext, useState, useContext } from 'react';
import DataContext from './DataContext';
/*
handleWordClick(vocab, word) => vocab comes from database, column fr_sentencetovocab. word comes from sentences, separated by a space
selectedPhrase => match word with a phrase & to color what user clicks
vocabulariesList => list of vocabulary to display
*/

  const ClickWord = createContext({
  });

  export const useClickWord = () => useContext(ClickWord);
  
  export const ClickWordProvider = ({ children }) => {
    const [selectedPhrase, setSelectedPhrase] = useState(''); // phrase clicked
    const [selectedVocab, setSelectedVocab] = useState({});   // vocab that phrase matches to
    const { fetchUpdatedVocab } = useContext(DataContext);

    const handleWordClick = async (vocab, word, sentence, index) => {
        if (typeof vocab === 'string') {
            try {
                vocab = JSON.parse(vocab);
            } catch (e) {
                console.error('Error parsing vocab:', e);
                setSelectedPhrase('');
                setSelectedVocab({});
                return;
            }
        }

        if (!vocab || typeof vocab !== 'object') {
            console.error('Invalid vocab object:', vocab);
            setSelectedPhrase('');
            setSelectedVocab({});
            return;
        }

        if(word === '?' || word === '!' || word === '"' || word === '.' || word === ',' || word === '/' || word === '-') {
            setSelectedPhrase(word);
            setSelectedVocab({});
        } else {
            let normalizedWord = '';
            // MUST MATCH THE ONE IN GETCLASSNAME FUNCTION IN UTILITIS.JS
            if (/\d/.test(word) && word.trim() !== '911,') {// if word contains number
              normalizedWord = word.toLowerCase().replace(/[.?!"]/g, '');
            } else {
              normalizedWord = word.toLowerCase().replace(/[.?,!"]/g, '');
            }

            let matchedPhrase = '';
            
            // Function to escape special characters in the normalizedWord
            // function escapeRegexChars(word) {
            //   return word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
            // }

            let matchedPhrases = Array.isArray(Object.keys(vocab))
              ? Object.keys(vocab).filter((phrase) => {
                  const lowerPhrase = phrase.toLowerCase();
                  const escapedWord = normalizedWord.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // Escape special characters in normalizedWord
                  
                  // Build a regular expression to match the exact conditions
                  const regex = new RegExp(`(^|\\s)${escapedWord}(\\s|$)`, 'i'); // Match normalizedWord at word boundaries (start or end or with spaces)
                  
                  // Return phrases that match the regex
                  return regex.test(lowerPhrase);
                })
              : [];

            if (matchedPhrases.length > 1) {
              const sentenceWords = sentence.toLowerCase().split(' '); // Assuming sentence is available and split by spaces
            
              // j'ai can be in j'ai or j'ai eu, so we have to see surrounding words
              // Define the words before and after the normalizedWord in the sentence
              const wordBefore = index > 0 ? sentenceWords[index - 1] : null;
              const wordAfter = index < sentenceWords.length - 1 ? sentenceWords[index + 1] : null;
              const wordBeforeBefore = index > 1 ? sentenceWords[index - 2] : null;
              const wordAfterAfter = index < sentenceWords.length - 2 ? sentenceWords[index + 2] : null;
            
              // Helper function to find the word different from normalizedWord in the phrase
              const findDifferentWord = (phraseWords, normalizedWord) => {
                return phraseWords.find((word) => word !== normalizedWord);
              };
            
              // Try to find the most exact match
              let exactMatch = null;
              let potentialMatch = null;
            
              matchedPhrases.forEach((phrase) => {
                const phraseWords = phrase.toLowerCase().split(' ');
                const differentWord = findDifferentWord(phraseWords, normalizedWord);
            
                if (!differentWord) {
                  // If no different word is found, consider it an exact match
                  exactMatch = phrase;
                } else if (
                  (wordBefore && differentWord === wordBefore) ||
                  (wordAfter && differentWord === wordAfter) ||
                  (wordBeforeBefore && differentWord === wordBeforeBefore) ||
                  (wordAfterAfter && differentWord === wordAfterAfter)
                ) {
                  // If the different word is found in one of the sentence positions
                  potentialMatch = phrase;
                }
              });
            
              // Prioritize potential match over exact match
              matchedPhrase = potentialMatch || exactMatch;
            } else if (matchedPhrases.length === 1) {
              matchedPhrase = matchedPhrases[0];
            } else {
              matchedPhrase = null; // No match found
            }
           
            if (matchedPhrase) {
                setSelectedPhrase(matchedPhrase);
                const vocabulary = vocab[matchedPhrase] || {};
                if (vocabulary.fr) {
                    try {
                      let updatedVocab;
                      updatedVocab = await fetchUpdatedVocab(vocabulary);
                                
                      // Ensure that updatedVocab is valid and has the expected structure
                      if (updatedVocab) {
                        // Merge updatedVocab into vocabulary (only add fields that are missing in vocabulary)
                        for (let key in updatedVocab) {
                            if (updatedVocab.hasOwnProperty(key) && !vocabulary.hasOwnProperty(key)) {
                            vocabulary[key] = updatedVocab[key]; // Copy missing fields from updatedVocab
                            }
                        }
            
                        // Set the final merged vocabulary as selectedVocab
                        setSelectedVocab(vocabulary);
                      } else {
                        setSelectedVocab(vocabulary); // fr not exist in database
                      }
                    } catch (error) {
                      console.error('Error fetching updated vocab:', error);
                    }
                  } else {
                    console.error('vocabulary.fr is undefined');
                  }              
                //setSelectedVocab(vocabulary);
            } else {
                setSelectedPhrase('');
                setSelectedVocab({});
            }
        }
    };

    return (
        <ClickWord.Provider value={{handleWordClick, selectedPhrase, setSelectedPhrase, selectedVocab, setSelectedVocab }}>
            {children}
        </ClickWord.Provider>
    );
}