import React, { useContext, useEffect } from 'react';
import { useLocation , useNavigate} from 'react-router-dom';
import DataContext from '../Reusables/DataContext';
import { translations } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';

const GrammarContent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { topicName } = location.state || { topicName: ''};
  const { grammarContent, fetchGrammarContent, setGrammarContent } = useContext(DataContext);
  const { selectedLanguage } = useLanguage();

  useEffect(() => {    
    fetchGrammarContent(topicName, selectedLanguage);
  }, [topicName]);

  const handleClick = (linkName) => {
    setGrammarContent([]);
    navigate('/grammar-topic', { state: { topicName: linkName }});
  };

  useEffect(() => {
    const links = document.querySelectorAll('.link');
    const handleClickWrapper = (event) => {
      const linkName = event.target.getAttribute('data-topic');
      handleClick(linkName);
    };

    links.forEach(link => {
      link.addEventListener('click', handleClickWrapper);
    });

    // Clean up event listeners on component unmount
    return () => {
      links.forEach(link => {
        link.removeEventListener('click', handleClickWrapper);
      });
    };
  }, [grammarContent]);

  return (   
    <div className='grammar-page'>
      <div className="top-row">
        <button onClick={() => navigate(-1)} className="back-button"> <img src={`/Icons/arrow-left.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].back} />  </button>
        <h3>{topicName}</h3>
        <div><BurgerMenuComponent /></div>  
      </div> 
      <div className='grammar-content'>
        <div >
          <br/> <br/>
          <div dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} dangerouslySetInnerHTML={{ __html: grammarContent }} />
        </div>
      </div>  
    </div>    
  );
};

export default GrammarContent;
