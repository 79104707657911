import React, { useState, useRef, useEffect, useContext } from 'react';
import { translations, showSweetAlert } from './Utilities';
import { useUserAnswers } from '../Practice/UserAnswers';
import { useLanguage } from './LanguageProvider';
import DataContext from '../Reusables/DataContext';

const FlagComponent = () => {
  const [isFlagClicked, setIsFlagClicked] = useState(false);
  const { selectedLanguage } = useLanguage();
  const [answer, setAnswer] = useState('');
  const { sendFeedback } = useContext(DataContext);
  const { program, level, englishTopic} = useUserAnswers();
  // const flagOptions = ['AIducare does not recognize my voice', 'There is a grammar or spelling error', 'Other issues'];

  const handleIconFlagClose = () => {
    setIsFlagClicked(false);
    setAnswer('');
  };

  const textareaRef = useRef(null);

  const adjustHeight = () => {
      const textarea = textareaRef.current;
      if (textarea) {
          textarea.style.height = 'auto'; // Reset the height
          const newHeight = Math.min(textarea.scrollHeight, 450); // Set a max height
          textarea.style.height = newHeight + 'px'; // Apply new height with max limit
      }
  };

  // Adjust height whenever the 'answer' changes
  useEffect(() => {
      adjustHeight();
  }, [answer]);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submit behavior        
    try {
      const fullFeedback = `Program = ${program}, Level = ${level}, Topic = ${englishTopic}, Feedback = ${answer}`;
      await sendFeedback(fullFeedback);
      showSweetAlert(translations[selectedLanguage].FeedbackSent, 'practice-message');
      setAnswer(''); 
      textareaRef.current.style.height = 'auto'; // Reset the textarea height
    } catch (error) {
        console.error('Error during submission:', error);
        showSweetAlert(translations[selectedLanguage].FeedbackNotSent, 'practice-message');
    }
  };

  const handleKeyDown = (event) => {
      adjustHeight(); 
  };

  return (
    <>
      <button className="menu-icon" onClick={() => setIsFlagClicked(true)}>
        <img style={{width:'25px', height:'25px'}}  src="/Icons/flag.svg" alt={translations[selectedLanguage].Flag}/>
      </button>
      {isFlagClicked && (
        <div dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} className="popup-burgermenu">
            <button className="x-button" onClick={handleIconFlagClose}><b>X</b></button>
            <form style={{border: '2px solid #ffffff', backgroundColor:'#ffffff', maxWidth:'1000px'}} onSubmit={handleSubmit} className="answer-form-flag">       
              <textarea
                  ref={textareaRef}
                  value={answer}
                  onChange={(e) => {
                    setAnswer(e.target.value);
                    adjustHeight();
                  }}
                  onKeyDown={handleKeyDown}
                  placeholder= {translations[selectedLanguage].TypeHere}
                  className="answer-input"
                  rows="1" // Starts with a single line
              />                        
            </form> 
            <button type="submit" className="submit-button" onClick={(e) => handleSubmit(e)}>
              <b>{translations[selectedLanguage].Submit}</b>
            </button>              
        </div>
      )}
    </>
  );
};

export default FlagComponent;