import React, { createContext, useState, useContext, useEffect } from 'react';
//TODO we will store users detected answers by conversations, % match for each sentence, average % score current and at the end of month for last 12 months. 
// In Achievement, we will show # finished conversations, average score %, average score %  last 12 months.

// Create the context
const UserAnswersContext = createContext({
    // conversationIndex: 0,
    // updateConversationIndex: () => {},
    // userSpeakAnswers: [{ pairs: [] }],
    // updateMatchPercentage: () => {}, 
    // updateMatchSuggestResponse: () => {},
  });

// Hook to use the context
export const useUserAnswers = () => useContext(UserAnswersContext);

// Context provider component
export const UserAnswersProvider = ({ children }) => {
    // const [program, setProgram] = useState(() => {
    //     return localStorage.getItem('program') || 'novice'; // Load from localStorage
    //   });
    // const [theme, setTheme] = useState(() => {
    //   return localStorage.getItem('theme') || ''; // Load from localStorage
    // });
    const [studyIndex, setStudyIndex] = useState(() => {      // is level_id
        return localStorage.getItem('studyIndex') || 0; // Load from localStorage
      });
    const [level, setLevel] = useState(() => {      // is level_id
      return localStorage.getItem('level') || 0; // Load from localStorage
    });
    const [topic, setTopic] = useState(() => {
      return localStorage.getItem('topic') || ''; 
    });
    const [englishTheme, setEnglishTheme] = useState(() => {
      return localStorage.getItem('englishTheme') || ''; 
    });
    const [englishTopic, setEnglishTopic] = useState(() => {
      return localStorage.getItem('englishTopic') || ''; 
    });
    const [speakConversationIndex, setSpeakConversationIndex] = useState(() => {
      return localStorage.getItem('speakConversationIndex') || 0; 
    });
    const [userSpeakAnswers, setUserSpeakAnswers] = useState(() => {
        const savedAnswers = localStorage.getItem('userSpeakAnswers');
        return savedAnswers ? JSON.parse(savedAnswers) : [
        {
            pairs: [
                { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
              ],
        },
        {
            pairs: [
                { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
              ],
        },
        {
            pairs: [
                { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
              ],
        },    
    ]; // Load answers as array
    });
    const [writeConversationIndex, setWriteConversationIndex] = useState(() => {
        return localStorage.getItem('writeConversationIndex') || 0; 
    });
    const [userWriteAnswers, setUserWriteAnswers] = useState(() => {
        const savedAnswers = localStorage.getItem('userWriteAnswers');
        return savedAnswers ? JSON.parse(savedAnswers) : [
            {
                pairs: [
                    { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                    { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                    { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                  ],
            },
            {
                pairs: [
                    { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                    { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                    { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                  ],
            },
            {
                pairs: [
                    { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                    { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                    { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                  ],
            },    
    ]; // Load answers as array
    });

    // Save in localStorage whenever it changes
    // useEffect(() => {
    //     localStorage.setItem('program', program);
    // }, [program]);
    // useEffect(() => {
    //   localStorage.setItem('theme', theme);
    // }, [theme]);
    useEffect(() => {
        localStorage.setItem('studyIndex', studyIndex);
      }, [studyIndex]);
    useEffect(() => {
      localStorage.setItem('level', level);
    }, [level]);
    useEffect(() => {
      localStorage.setItem('topic', topic);
    }, [topic]);
    useEffect(() => {
        localStorage.setItem('englishTheme', englishTheme);
    }, [englishTheme]);
    useEffect(() => {
      localStorage.setItem('englishTopic', englishTopic);
    }, [englishTopic]);
    useEffect(() => {
        localStorage.setItem('speakConversationIndex', speakConversationIndex);
    }, [speakConversationIndex]);
    useEffect(() => {
        localStorage.setItem('userSpeakAnswers', JSON.stringify(userSpeakAnswers));
    }, [userSpeakAnswers]);
    useEffect(() => {
        localStorage.setItem('writeConversationIndex', writeConversationIndex);
    }, [writeConversationIndex]);
    useEffect(() => {
        localStorage.setItem('userWriteAnswers', JSON.stringify(userWriteAnswers));
    }, [userWriteAnswers]);

    // Functions to update theme, ...
    // const updateProgram = (newProgram) => setProgram(newProgram);
    // const updateTheme = (newTheme) => setTheme(newTheme);
    const updateStudyIndex = (newStudyIndex) => setStudyIndex(newStudyIndex);
    const updateLevel = (newLevel) => setLevel(newLevel);
    const updateTopic = (newTopic) => setTopic(newTopic);
    const updateEnglishTheme = (newEnglishTheme) => setEnglishTheme(newEnglishTheme);
    const updateEnglishTopic = (newEnglishTopic) => setEnglishTopic(newEnglishTopic);
    const updateSpeakConversationIndex = (newSpeakConversationIndex) => setSpeakConversationIndex(newSpeakConversationIndex);
    const updateWriteConversationIndex = (newWriteConversationIndex) => setWriteConversationIndex(newWriteConversationIndex);
    
    const updateUserSpeakAnswer = (conversationIndex, pairIndex, newUserAnswer) => {
        setUserSpeakAnswers(prevUserAnswers => {
            const newUserAnswers = [...prevUserAnswers];
            newUserAnswers[conversationIndex].pairs[pairIndex] = {
                ...newUserAnswers[conversationIndex].pairs[pairIndex],
                userAnswer: newUserAnswer,
            };
            return newUserAnswers;
        });
    };

    const updateSpeakMatchPercentage = (conversationIndex, pairIndex, newMatchPercentage) => {
        setUserSpeakAnswers(prevUserAnswers => {
            const newUserAnswers = [...prevUserAnswers];
            newUserAnswers[conversationIndex].pairs[pairIndex] = {
                ...newUserAnswers[conversationIndex].pairs[pairIndex],
                matchPercentage: newMatchPercentage,
            };
            return newUserAnswers;
        });
    };

    const updateSpeakMatchSuggestResponse = (conversationIndex, pairIndex, newMatchSuggestResponse) => {
        setUserSpeakAnswers(prevUserAnswers => {
            const newUserAnswers = [...prevUserAnswers];
            newUserAnswers[conversationIndex].pairs[pairIndex] = {
                ...newUserAnswers[conversationIndex].pairs[pairIndex],
                matchSuggestResponse: newMatchSuggestResponse,
            };
            return newUserAnswers;
        });
    };

    const updateUserWriteAnswer = (conversationIndex, pairIndex, newUserAnswer) => {
        setUserWriteAnswers(prevUserAnswers => {
            const newUserAnswers = [...prevUserAnswers];
            newUserAnswers[conversationIndex].pairs[pairIndex] = {
                ...newUserAnswers[conversationIndex].pairs[pairIndex],
                userAnswer: newUserAnswer,
            };
            return newUserAnswers;
        });
    };

    const updateWriteMatchPercentage = (conversationIndex, pairIndex, newMatchPercentage) => {
        setUserWriteAnswers(prevUserAnswers => {
            const newUserAnswers = [...prevUserAnswers];
            newUserAnswers[conversationIndex].pairs[pairIndex] = {
                ...newUserAnswers[conversationIndex].pairs[pairIndex],
                matchPercentage: newMatchPercentage,
            };
            return newUserAnswers;
        });
    };

    const updateWriteMatchSuggestResponse = (conversationIndex, pairIndex, newMatchSuggestResponse) => {
        setUserWriteAnswers(prevUserAnswers => {
            const newUserAnswers = [...prevUserAnswers];
            newUserAnswers[conversationIndex].pairs[pairIndex] = {
                ...newUserAnswers[conversationIndex].pairs[pairIndex],
                matchSuggestResponse: newMatchSuggestResponse,
            };
            return newUserAnswers;
        });
    };

    const resetUserSpeakAnswers = (conversationIndex) => {
        setUserSpeakAnswers(prevUserAnswers => 
            prevUserAnswers.map((conversation, index) => 
                index === conversationIndex 
                    ? {
                        ...conversation,
                        pairs: [
                            {
                                userAnswer: '',
                                matchPercentage: 0,
                                matchSuggestResponse: ''
                            }
                        ]
                      }
                    : conversation
            )
        );
    };   

    const resetUserWriteAnswers = (conversationIndex) => {
        setUserWriteAnswers(prevUserAnswers => 
            prevUserAnswers.map((conversation, index) => 
                index === conversationIndex 
                    ? {
                        ...conversation,
                        pairs: [
                            {
                                userAnswer: '',
                                matchPercentage: 0,
                                matchSuggestResponse: ''
                            }
                        ]
                      }
                    : conversation
            )
        );
    };

    return (
        <UserAnswersContext.Provider value={{     
            // program, 
            // theme,
            studyIndex,
            level, 
            topic,
            englishTheme,
            englishTopic,
            updateStudyIndex,
            updateLevel, 
            // updateProgram,           
            // updateTheme,
            updateTopic,
            updateEnglishTheme,
            updateEnglishTopic,
            speakConversationIndex,
            updateSpeakConversationIndex,
            userSpeakAnswers,
            updateUserSpeakAnswer,
            updateSpeakMatchPercentage,
            updateSpeakMatchSuggestResponse,
            writeConversationIndex,
            updateWriteConversationIndex,
            userWriteAnswers,
            updateUserWriteAnswer,
            updateWriteMatchPercentage,
            updateWriteMatchSuggestResponse,
            resetUserSpeakAnswers,
            resetUserWriteAnswers
        }}>
            {children}
        </UserAnswersContext.Provider>
    );
};
  
  
   // Function to update a specific pair's properties
   //to use: updateUserAnswer(0, 1, {
        //     userAnswer: 'Updated answer',
        //     matchPercentage: 75,
        //     matchSuggestResponse: 'New suggested response',
        // }
//    const updateUserAnswerRecord = (conversationIndex, pairIndex, data) => {
//     setUserAnswers(prevUserAnswers => {
//         const newUserAnswers = [...prevUserAnswers];
//         newUserAnswers[conversationIndex].pairs[pairIndex] = {
//             ...newUserAnswers[conversationIndex].pairs[pairIndex],
//             ...data, 
//         };
//         return newUserAnswers;
//     });