import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import DataContext from '../Reusables/DataContext';
import { useUserAnswers  } from './UserAnswers';
import { showSweetAlert, showLastSweetAlert, getMatchPercentage, frToAudio, translations, getTranslatedSentence } from '../Reusables/Utilities';
import { usePlayAudio, PlaybackRateControl } from '../Reusables/PlayAudio';
import { useRecordUser } from '../Reusables/RecordUser';
import { useLanguage } from '../Reusables/LanguageProvider';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import BottomMenuComponent from '../Reusables/BottomMenuComponent';
import './Practice.css'; 

const PracticeSpeaking = () => {
  let navigate = useNavigate();    
  const { conversations, endWithAI } = useContext(DataContext);
  const { selectedLanguage } = useLanguage();
  
  useEffect(() => {
    if (conversations.length > 0 ){
      setVisiblePairs([conversations[conversationIndex].pairs[currentIndex]]); // Start with the first pair visible
      setSituationDescription(conversations[conversationIndex].description);
    }
  }, []);

  const goBack = () => {
    stopRecording();
    navigate(-1);    
  }
  
  /********** CONVERSATION **********/ 
  const { level, englishTopic, speakConversationIndex, resetUserSpeakAnswers} = useUserAnswers();
  const [conversationIndex, setConversationIndex] = useState(speakConversationIndex);
  const [currentIndex, setCurrentIndex] = useState(0); // which pair of application-user 
  // const [visiblePairs, setVisiblePairs] = useState([conversations[conversationIndex].pairs[currentIndex]]); // Start with the first pair visible
  const [visiblePairs, setVisiblePairs] = useState([]);
  const [showDescription, setShowDescription] = useState(true);  
  const [answersIndex, setAnswersIndex] = useState(null);     // which answer
  const [answersIdx, setAnswersIdx] = useState(null);     // which answer
  const [situationDescription, setSituationDescription] = useState('');
  const {isPlaying, isPlayingSlower, playPause, stopAudio, slowerPause, currentAudioFile} = usePlayAudio();  
    
  useEffect(() => {
    if (conversations.length > 0){
      setSituationDescription(conversations[conversationIndex].description);
      setCurrentIndex(0);    
      setVisiblePairs([conversations[conversationIndex].pairs[0]]); 
      setAnswersIndex(null);
      setAnswersIdx(null);
      setTranscriptionIndex(null);
      setTranscriptionText('');
      setTranslationIndex(null);
      setTranslationText('');
      setUserTranslationIndex(null);
      setUserTranslationIdx(null);
      setUserTranslationText(''); 
    }
  }, [conversationIndex]);

  useEffect(() => {
    // Stop the audio when the component unmounts (e.g., when navigating to another page)
    return () => {
      stopAudio(); // This will run when the component unmounts
    };
  }, []); // Empty dependency array means this effect runs only once, and the cleanup runs when the component unmounts
   

  useEffect(() => {
    if (currentIndex === 0){
      resetUserSpeakAnswers(conversationIndex);
    } 
    if (conversations.length !== 0){
      if (endWithAI && currentIndex === (conversations[conversationIndex].pairs.length - 1)) {
        closeAudio();
        const timer = setTimeout(() => {
          navigate('/pronunciation-report'); 
        }, 10000);
        return () => clearTimeout(timer);
      }
    }
  }, [currentIndex]); 

  const changeSituation = () => {
    const nextConversationIndex = conversationIndex + 1 < conversations.length ? conversationIndex + 1 : 0;
    setConversationIndex(nextConversationIndex);
    stopAudio();
  }

  const toggleShowAnswer = (index,idx) => {
    if (answersIndex === index && answersIdx === idx) {
      setAnswersIndex(null);
      setAnswersIdx(null);
    } else{
      setAnswersIndex(index);   
      setAnswersIdx(idx);  
    }           
  };
  
  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  const handleStartRecording = () => {       
    if (endWithAI && currentIndex === (conversations[conversationIndex].pairs.length - 1)) {  
      closeAudio();      
      navigate('/pronunciation-report'); 
    } else {
      stopAudio();
      startRecording(selectedLanguage);
    }        
  };    
  
  const nextConversationPair = () => {
    const nextIndex = currentIndex + 1; 
    if (nextIndex < conversations[conversationIndex].pairs.length) {
      setVisiblePairs(prevVisiblePairs => [...prevVisiblePairs, conversations[conversationIndex].pairs[nextIndex]]);    
      setCurrentIndex(nextIndex);
      playPause(frToAudio(conversations[conversationIndex].pairs[nextIndex].application.fr, level, englishTopic));           // play next application sentence once
    } else {
      stopAudio();
      stopRecording();     
    }
  };  

  //auto scroll to the latest conversation pair
  const messagesEndRef = useRef(null);
  useEffect(() => {
    const scrollToEnd = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Use setTimeout to ensure the SweetAlert has rendered before scrolling
    const timeoutId = setTimeout(scrollToEnd, 100);

    return () => clearTimeout(timeoutId);
  }, [visiblePairs]);

  /********** TRANSCRIPTION **********/ 
  const [transcriptionText, setTranscriptionText] = useState('');
  const [transcriptionIndex, setTranscriptionIndex] = useState(null); 

  const transribeApplication = (text, index) => {
    if (transcriptionIndex === index) {
      setTranscriptionIndex(null);
      setTranscriptionText(''); // Clear transcription text
    } else {
      setTranscriptionIndex(index);
      setTranscriptionText(text); // Set transcription text
    }
  };
  
  /********** TRANSLATION **********/ 
  const [translationText, setTranslationText] = useState('');
  const [translationIndex, setTranslationIndex] = useState(null); 
  const [userTranslationText, setUserTranslationText] = useState('');
  const [userTranslationIndex, setUserTranslationIndex] = useState(null); 
  const [userTranslationIdx, setUserTranslationIdx] = useState(null); 

  const translateApplication = (text, index) => {
    if (translationIndex === index) {
      setTranslationIndex(null);
      setTranslationText(''); 
    } else {
      setTranslationIndex(index);
      setTranslationText(text);
    }
  };

  const translateSuggestAnswer = (text, index, idx) => {
    if(userTranslationIndex === index && userTranslationIdx === idx){
      setUserTranslationIndex(null);
      setUserTranslationIdx(null);
      setUserTranslationText(''); 
    } else{
      setUserTranslationIndex(index);
      setUserTranslationIdx(idx);
      setUserTranslationText(text);
    }
  };
  
  /********** USER SPEECH **********/   
  const {closeAudio, transcription, recording, startRecording, stopRecording} = useRecordUser();
  const [highestMatch, setHighestMatch] = useState([0]);
  const { userSpeakAnswers, updateSpeakConversationIndex, updateUserSpeakAnswer, updateSpeakMatchPercentage, updateSpeakMatchSuggestResponse } = useUserAnswers();
  const [bestMatchSentence, setBestMatchSentence] = useState(['']);
  const previousTranscriptionRef = useRef(transcription);
  const userInteractionRef = useRef(false);
 
  useEffect(() => {      
    if (transcription !== ''){ 
      console.log('detected sentence = ' + transcription); 
      previousTranscriptionRef.current = transcription;
      if(!userInteractionRef.current){
          userInteractionRef.current = true;
      }
      if(transcription.replace(/[.,?!'"']/g, '') !== '') {
          findBestMatch();
      }
    }    
  }, [transcription]);  
 
  function findBestMatch() {
    if(visiblePairs.length > currentIndex && visiblePairs[currentIndex] && visiblePairs[currentIndex].suggestResponses){
      const sentencesA = visiblePairs[currentIndex].suggestResponses;
      let newHighestMatches = [...highestMatch]; // Clone the current state to modify
  
  
      sentencesA.forEach(sentence => {
          let matchPercentage = getMatchPercentage(transcription, sentence.fr);        
               
          if (currentIndex >= newHighestMatches.length) {
            newHighestMatches.push(0);  // Extend the highestMatch array if new answer          
          }
  
  
          // Update the highest match if the new match is better
          if (matchPercentage >= newHighestMatches[currentIndex]) {
            newHighestMatches[currentIndex] = matchPercentage;
            setBestMatchSentence(sentence.fr);
          }            
      });    
     
      // Update the state only once after all calculations are done
      setHighestMatch(newHighestMatches);
    }    
  }  

  useEffect(() => {  
      if (userInteractionRef.current) {
        updateSpeakConversationIndex (conversationIndex);
        updateUserSpeakAnswer(conversationIndex, currentIndex, transcription.replace(/["]/g, ''));
        updateSpeakMatchPercentage(conversationIndex, currentIndex, highestMatch[currentIndex]);
        updateSpeakMatchSuggestResponse(conversationIndex, currentIndex, bestMatchSentence);
        let value = highestMatch[currentIndex];
        let message;
        let iconUrl;

        switch (true) {
          case (value < 10):
            message = translations[selectedLanguage].DontGiveUp; 
            iconUrl = 'Icons/dontgiveup.svg'; 
            break;
          case (value >= 10 && value < 20):
            message = translations[selectedLanguage].YouCanDoIt; 
            iconUrl = 'Icons/youcandoit.svg';   
            break;
          case (value >= 20 && value < 30):
            message = translations[selectedLanguage].KeepPracticing;
            iconUrl = 'Icons/keeppracticing.svg';
            break;
          case (value >= 30 && value < 40):
            message = translations[selectedLanguage].KeepGoing;
            iconUrl = 'Icons/keepgoing.svg';  
            break;
          case (value >= 40 && value < 50):
            message = translations[selectedLanguage].NotBad
            iconUrl = 'Icons/notbad.svg'; 
            break;
          case (value >= 50 && value < 60):
            message = translations[selectedLanguage].GoodJob;
            iconUrl = 'Icons/goodjob.svg';
            break;
          case (value >= 60 && value < 70):
            message = translations[selectedLanguage].Bravo;
            iconUrl = 'Icons/bravo.svg';
            break;
          case (value >= 70 && value < 75):
            message = translations[selectedLanguage].Super;
            iconUrl = 'Icons/super.svg';
            break;
          case (value >= 75 && value < 80):
            message = translations[selectedLanguage].Excellent;
            iconUrl = 'Icons/excellent.svg';
            break;
          case (value >= 80 && value < 90):
            message = translations[selectedLanguage].Wow;
            iconUrl = 'Icons/wow.svg';
            break;
          case (value >= 90):
            message = translations[selectedLanguage].Pro;
            iconUrl = 'Icons/pro.svg';
            break;
          default:
            message = translations[selectedLanguage].OutOfRange;         
        }
        if (currentIndex < conversations[conversationIndex].pairs.length - 1) {
          showSweetAlert(message, 'practice-message', iconUrl);
        } else if (currentIndex === conversations[conversationIndex].pairs.length - 1){
          closeAudio();
          showLastSweetAlert(message, 'practice-message', iconUrl, navigate, '/pronunciation-report');
        }
        nextConversationPair();
      }
  }, [ highestMatch]);  

  const showEncoragement = () => {
    let value = userSpeakAnswers[conversationIndex].pairs[currentIndex].matchPercentage;
    let message;
    let iconUrl;

    switch (true) {
      case (value < 10):
          message = translations[selectedLanguage].DontGiveUp; 
          iconUrl = 'Icons/dontgiveup.svg'; 
          break;
        case (value >= 10 && value < 20):
          message = translations[selectedLanguage].YouCanDoIt; 
          iconUrl = 'Icons/youcandoit.svg';   
          break;
        case (value >= 20 && value < 30):
          message = translations[selectedLanguage].KeepPracticing;
          iconUrl = 'Icons/keeppracticing.svg';
          break;
        case (value >= 30 && value < 40):
          message = translations[selectedLanguage].KeepGoing;
          iconUrl = 'Icons/keepgoing.svg';  
          break;
        case (value >= 40 && value < 50):
          message = translations[selectedLanguage].NotBad
          iconUrl = 'Icons/notbad.svg'; 
          break;
        case (value >= 50 && value < 60):
          message = translations[selectedLanguage].GoodJob;
          iconUrl = 'Icons/goodjob.svg';
          break;
        case (value >= 60 && value < 70):
          message = translations[selectedLanguage].Bravo;
          iconUrl = 'Icons/bravo.svg';
          break;
        case (value >= 70 && value < 75):
          message = translations[selectedLanguage].Super;
          iconUrl = 'Icons/super.svg';
          break;
        case (value >= 75 && value < 80):
          message = translations[selectedLanguage].Excellent;
          iconUrl = 'Icons/excellent.svg';
          break;
        case (value >= 80 && value < 90):
          message = translations[selectedLanguage].Wow;
          iconUrl = 'Icons/wow.svg';
          break;
        case (value >= 90):
          message = translations[selectedLanguage].Pro;
          iconUrl = 'Icons/pro.svg';
          break;
        default:
          message = translations[selectedLanguage].OutOfRange;
    }
    showSweetAlert(message, 'practice-message', iconUrl);   
  }


  /********** POPUP INSTRUCTION **********/
  const situationRef = useRef(null);
  const changeRef = useRef(null);  
  const transcriptionRef = useRef(null); 
  const optionRef = useRef(null);
  const microphoneRef = useRef(null);

  const steps = [
    { ref: situationRef, label: translations[selectedLanguage].SituationLabel, text: translations[selectedLanguage].situationDescription },
    { ref: changeRef, label: translations[selectedLanguage].changeLabel, text: translations[selectedLanguage].changeSituation},
    { ref: transcriptionRef, label: translations[selectedLanguage].TranscriptionLabel, text: translations[selectedLanguage].Seetranscription },
    { ref: optionRef, label: translations[selectedLanguage].Options, text: translations[selectedLanguage].possibleAnswers },
    { ref: microphoneRef, label: translations[selectedLanguage].MicrophoneLabel, text: translations[selectedLanguage].Recordyouranswers },    
  ];

  /********* dynamically adjust the viewport height on mobile *********/
  useEffect(() => {
    const handleResize = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };
  
    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initially
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 
  
  /********** INTERFACE **********/ 
  return (
    <div >
    {conversations.length > 0 ? (
    <div className="practice-page">
    <div className="top-row">
        <button onClick={() => goBack()} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>
        <h3>{translations[selectedLanguage].PracticeSpeaking}</h3>
        <BurgerMenuComponent />        
      </div> 

      <div className="practice-body">
      <div style={{display:'flex', justifyContent: 'space-between', marginTop:'5px' }}>
        <button ref={situationRef} className="situation-button" onClick={toggleDescription}>
            <div style={{marginRight:"auto"}}>{translations[selectedLanguage].Situation}</div>
            &nbsp;&nbsp;
            <div style={{marginLeft:"auto"}}>{showDescription ? ( 
                <img src={`/Icons/arrow-up.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].collapse} />
            ) : (
                <img src={`/Icons/arrow-down.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].expand} />
            )}</div>
        </button>        
        <button ref={changeRef} className="situation-change" onClick={changeSituation}>
            <div style={{marginRight:"auto"}}>{translations[selectedLanguage].change}</div>
            &nbsp;&nbsp;&nbsp;
            <img src={`/Icons/change.svg?${new Date().getTime()}`} alt="" />       
        </button>
      </div>
      {showDescription && 
        <div dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} className="situation" style={{ marginTop:'5px' }}>{situationDescription.split('<br/>').map((line, index, array) => (
            <React.Fragment key={index}>
            {line}
            {index < array.length - 1 && <br />}
            </React.Fragment>
        ))}</div> 
      }

    <div className="practice-container">
      <div className="conversation-dialog-container">
      {visiblePairs.map((pair, index) => (        
      <div key={index} className="conversation-dialog">
        <span  className="situation-change" style={{marginRight:'auto'}}>{translations[selectedLanguage].AI}</span>
        <div className="application-user-container">
          <div className="application-answer">            
            <div >              
              <button  style={{marginLeft: '0px'}} className="speaker-button-practice" onClick={() => playPause(frToAudio(pair.application.fr, level, englishTopic))}>
               <img src={isPlaying && currentAudioFile === frToAudio(pair.application.fr, level, englishTopic) ? "Icons/pause-practice-application-active.svg" : "Icons/listen-summary.svg"} alt={isPlaying ? translations[selectedLanguage].Pause : translations[selectedLanguage].Listen} />
              </button>
              <button style={{transform:'translateX(-3px)'}}  className="slowdown-button-practice" onClick={() => slowerPause(frToAudio(pair.application.fr, level, englishTopic))}>
                <img src={isPlayingSlower && currentAudioFile === frToAudio(pair.application.fr, level, englishTopic) ? "Icons/pause-practice-application-active.svg" : "Icons/turtle-summary.svg"} alt={isPlaying ? translations[selectedLanguage].Pause : translations[selectedLanguage].Slow} /> 
              </button>
              <button style={{transform:'translate(5px, -3px)'}}  className="translation-button-practice" onClick={() => translateApplication(getTranslatedSentence(pair.application, selectedLanguage), index)}>  
                  <img src={translationIndex === index && translationText !== '' ? "Icons/translation-practice-active.svg" : "Icons/translation-practice.svg"} alt={translations[selectedLanguage].Translation} />
              </button>  
              <button ref={transcriptionRef} style={{transform:'translateY(-3px)'}} className="transcription-button-practice" onClick={() => transribeApplication(pair.application.fr, index)}>
                <img  src={transcriptionIndex === index && transcriptionText !== '' ? "Icons/transcription-practice-application-active.svg" : "Icons/transcription-practice.svg"} alt={translations[selectedLanguage].Transcription} />
              </button>
            </div>               
            {isPlayingSlower && currentAudioFile === frToAudio(pair.application.fr, level, englishTopic) && <div style={{minWidth:'300px'}}><PlaybackRateControl /> </div>}                          
            {(transcriptionIndex === index ||  (translationIndex === index && translationText !== '')) && <hr/>}
            {translationIndex === index && (
              <div dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} style={{color:'#E07F9C'}}>{translationText}</div>
            )} 
            {transcriptionIndex === index && (              
              <div style={{color:'#ffffff'}}>{transcriptionText}</div>
            )}        
          </div>  
        </div>

        <span className="situation-change" style={{marginLeft:'auto'}}>{translations[selectedLanguage].User}</span>
        <div className="user-answers-container">   
        {(selectedLanguage === 'ar' || selectedLanguage === 'fa') ?
          <div className="user-answers">
            <div dir='rtl' style={{color:'#E07F9C'}}><strong>{translations[selectedLanguage].HINT}: </strong>{pair.hint}</div>
            <hr />
            {pair.suggestResponses.map((response, idx) => (
              <div key={idx} >
                { idx < 3 && (<>
                <div className="user-answer">
                <button style={{marginLeft:'0px'}} className="speaker-button-practice" onClick={() => playPause(frToAudio(response.fr, level, englishTopic))}>
                  <img src={isPlaying && currentAudioFile === frToAudio(response.fr, level, englishTopic) ? "Icons/pause-summary.svg" : "Icons/listen-summary.svg"} alt={isPlaying && currentAudioFile === frToAudio(response.fr, level, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Listen} />
                </button> 
                <button style={{transform:'translateX(-3px)'}} className="slowdown-button-practice" onClick={() => slowerPause(frToAudio(response.fr, level, englishTopic))}>
                  <img src={isPlayingSlower && currentAudioFile === frToAudio(response.fr, level, englishTopic) ? "Icons/pause-summary.svg" : "Icons/turtle-summary.svg"} alt={isPlaying && currentAudioFile === frToAudio(response.fr, level, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Slow} />
                </button>                 
                <button style={{transform:'translateX(5px)'}} className="translation-button-practice" onClick={() => translateSuggestAnswer(getTranslatedSentence(response, selectedLanguage), index, idx)}>
                  <img src={userTranslationIndex === index && userTranslationIdx === idx && userTranslationText !== '' ? "Icons/translation-practice-active.svg" : "Icons/translation-practice.svg"} alt={translations[selectedLanguage].Translation} />
                </button>
                <button style={{marginRight:'20px'}} className="transcription-button-practice" onClick={() => toggleShowAnswer(index, idx)}>
                  <img  src={answersIndex === index && answersIdx === idx ? "Icons/transcription-practice-user-active.svg" : "Icons/transcription-practice.svg"} alt={translations[selectedLanguage].Transcription} />
                </button>   
                <div dir='rtl' ref={optionRef} ><b>{translations[selectedLanguage].Option}{` ${idx + 1}: `}</b></div>  
                </div>
                {isPlayingSlower && currentAudioFile === frToAudio(response.fr, level, englishTopic) && <div style={{minWidth:'300px'}}><PlaybackRateControl /> </div>}                  
                {userTranslationIndex === index && userTranslationIdx === idx && (
                  <div dir='rtl' style={{color:'#E07F9C'}}>{userTranslationText}</div>
                )}  
                {answersIndex === index && answersIdx === idx && (
                  <div style={{color:'#062E5F'}}>{response.fr}</div>                  
                )}               
                {idx < Math.min(2, pair.suggestResponses.length - 1) && <hr />}
                </>)}                   
              </div>
            ))}            
          </div>
        :
<div className="user-answers">
            <div style={{color:'#E07F9C'}}><strong>{translations[selectedLanguage].HINT}: </strong>{pair.hint}</div>
            <hr />
            {pair.suggestResponses.map((response, idx) => (
              <div key={idx} >
                { idx < 3 && (<>
                <div className="user-answer">
                 <div  ref={optionRef} ><b>{translations[selectedLanguage].Option}{` ${idx + 1}: `}</b></div>
                <button className="speaker-button-practice" onClick={() => playPause(frToAudio(response.fr, level, englishTopic))}>
                  <img src={isPlaying && currentAudioFile === frToAudio(response.fr, level, englishTopic) ? "Icons/pause-summary.svg" : "Icons/listen-summary.svg"} alt={isPlaying && currentAudioFile === frToAudio(response.fr, level, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Listen} />
                </button> 
                <button style={{transform:'translateX(-3px)'}} className="slowdown-button-practice" onClick={() => slowerPause(frToAudio(response.fr, level, englishTopic))}>
                  <img src={isPlayingSlower && currentAudioFile === frToAudio(response.fr, level, englishTopic) ? "Icons/pause-summary.svg" : "Icons/turtle-summary.svg"} alt={isPlaying && currentAudioFile === frToAudio(response.fr, level, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Slow} />
                </button>                 
                <button style={{transform:'translateX(5px)'}} className="translation-button-practice" onClick={() => translateSuggestAnswer(getTranslatedSentence(response, selectedLanguage), index, idx)}>
                  <img src={userTranslationIndex === index && userTranslationIdx === idx && userTranslationText !== '' ? "Icons/translation-practice-active.svg" : "Icons/translation-practice.svg"} alt={translations[selectedLanguage].Translation} />
                </button>
                <button className="transcription-button-practice" onClick={() => toggleShowAnswer(index, idx)}>
                  <img  src={answersIndex === index && answersIdx === idx ? "Icons/transcription-practice-user-active.svg" : "Icons/transcription-practice.svg"} alt={translations[selectedLanguage].Transcription} />
                </button>   
                </div>
                {isPlayingSlower && currentAudioFile === frToAudio(response.fr, level, englishTopic) && <div style={{minWidth:'300px'}}><PlaybackRateControl /> </div>}                  
                {userTranslationIndex === index && userTranslationIdx === idx && (
                  <div style={{color:'#E07F9C'}}>{userTranslationText}</div>
                )}  
                {answersIndex === index && answersIdx === idx && (
                  <div style={{color:'#062E5F'}}>{response.fr}</div>                  
                )}               
                {idx < Math.min(2, pair.suggestResponses.length - 1) && <hr />}
                </>)}                   
              </div>
            ))}            
          </div>
}


          
        </div>
      </div>
      ))}
      <div ref={messagesEndRef} />
      </div></div>

      <div className="microphone-practice" onClick={recording ? stopRecording  : handleStartRecording}> 
        {recording ? (
            <button className="recording-animation-practice" style={{scale:'0.8'}}>
            <img src="Icons/stop-practice.svg" alt={translations[selectedLanguage].Stop} />          
            </button>
        )
        :
          <button ref={microphoneRef} className="microphone-button-practice">
          <img src="Icons/microphone-practice.svg" alt={translations[selectedLanguage].Microphone} />
          </button>
        }
        <br/><br/>
      </div>

        </div>
        <BottomMenuComponent steps={steps}/>
        </div>      
        ) : (
          <button className="topic-option" onClick={() => navigate('/')}>{translations[selectedLanguage].PleaseChooseALevel}</button>        
        )} 
    </div>
  );
};

export default PracticeSpeaking;