import React, {useContext, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { translations, getInstructionVideo } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import { useUserAnswers  } from '../Practice/UserAnswers';
import DataContext from '../Reusables/DataContext';
import './Home.css'; 

const Home = () => {
  let navigate = useNavigate();
  const { selectedLanguage, updateSelectedLanguage } = useLanguage(); 
  const {level, updateLevel} = useUserAnswers();
  const { levels, fetchLevels, topics, setTopics } = useContext(DataContext);
  const [clickStart, setClickStart] = useState(false);

  // const levels = [
  //   { code: 'novice', name: translations[selectedLanguage].Novicelevel}, 
  //   { code: 'generalEasy', name: translations[selectedLanguage].GenerallevelEasy },   
  //   { code: 'general', name: translations[selectedLanguage].Generallevel }
  // ];

  useEffect(() => {
    fetchLevels(selectedLanguage);    
  }, []);

  const handleLevelChange = (e) => {
    updateLevel(e.target.value);
  };
  
  const languages = [
    { code: 'en', name: 'English'}, 
    { code: 'es', name: 'Español' },   // Spanish
    { code: 'pt_br', name: 'Português' }, // Portuguese
    { code: 'ar', name: 'العربية' },   // Arabic
    { code: 'fa', name: 'فارسی' },     // Persian (Farsi)
    { code: 'zh', name: '中文' },       // Simplified Chinese
    { code: 'vi', name: 'Tiếng Việt' } // Vietnamese
  ];

  const handleLanguageChange = (e) => {
    updateSelectedLanguage(e.target.value);
    console.log("Language selected:", e.target.value);
    fetchLevels(e.target.value);   
  };

  useEffect(() => {    
    if (clickStart && topics.length === 0){
      navigate('/topic-selection');
    }
  }, [clickStart]);

  const handleStart = () => {
    setTopics([]);
    setClickStart(true); 

    // switch(level.level_id){
    //   case 1:
    //     navigate('/novice-program');
    //     break;
    //   case 'general':
    //       navigate('/theme-selection');
    //       break;
    //   default:
    // }   
  };

  /********** instruction video **********/  
  const [showVideo, setShowVideo] = useState(false);

  const handleVideoInstructionClick = () => {
    setShowVideo(true);
  };

  const handleCloseVideo = () => {
    setShowVideo(false);
  };

  return (
    <div className="start-container">
      <div className="logo">        
        <img src="logo-header-center.png" alt={translations[selectedLanguage].Logo} />
      </div>
      
      <div className="language-selector">
        <select value={selectedLanguage} onChange={handleLanguageChange}>
          {languages.map((language) => (
            <option key={language.code} value={language.code}>
              {language.name}
            </option>
          ))}
        </select>
      </div>

      <div className="level-selector">
        <select value={level} onChange={handleLevelChange}>
          {levels.map((level) => (
            <option key={level.level_id} value={level.level_id}>
              {level.level_name}
            </option>
          ))}
        </select>
      </div>

      <button className="instruction-link" onClick={handleVideoInstructionClick}>{translations[selectedLanguage].InstructionVideo}</button> 
      {showVideo && (
        <div className="video-popup-overlay">
          <button className="video-close-button" onClick={handleCloseVideo}>X</button>
          <video controls className="responsive-video">
            <source src={getInstructionVideo(selectedLanguage)} type="video/mp4" />
            {translations[selectedLanguage].Doesnotsupportvideo}
          </video>
        </div>
      )}

      <button className="start-button" onClick={handleStart}>
        <b>{translations[selectedLanguage].start} </b>
      </button>
    </div>
  );
};

export default Home;
