
import React, { useState, useEffect, useRef, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import DataContext from '../Reusables/DataContext';
import { usePlayAudio, PlaybackRateControl } from '../Reusables/PlayAudio';
import { frToAudio } from '../Reusables/Utilities';
import { translations, getTranslatedSentence } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import BottomMenuComponent from '../Reusables/BottomMenuComponent';
import { useUserAnswers } from '../Practice/UserAnswers';

const SummaryPage = () => {
  const navigate = useNavigate();  
  const { sentencesForStudy, fetchConversations } = useContext(DataContext);
  const { program, level, englishTopic} = useUserAnswers();
  const { selectedLanguage } = useLanguage();
  const {topic, isPlaying, isPlayingSlower, playPause, stopAudio, slowerPause, currentAudioFile} = usePlayAudio(); 

  useEffect(() => {
    if (sentencesForStudy.length === 0) {
      fetchConversations(selectedLanguage, program, topic);
    } 
  }, []);


  useEffect(() => {
    // Stop the audio when the component unmounts (e.g., when navigating to another page)
    return () => {
      stopAudio(); // This will run when the component unmounts
    };
  }, []); // Empty dependency array means this effect runs only once, and the cleanup runs when the component unmounts
  
  const goBack = () => { 
      navigate(-1);
  }

  // Helper functions to navigate to the specific sentence
  const navigateToStudySentence = (index) => {
    // stopAudio();
    navigate('/speaking-sentences', { state: { index } });
  };

  const goToPractice = () => {
    // stopAudio();
    navigate('/practice');
  };

  /********** TRANSLATION **********/ 
  const [showTranslationSpeak, setShowTranslationSpeak] = useState(true);
  const [translationText, setTranslationText] = useState('');
  const [translationSpeakIndex, setTranslationSpeakIndex] = useState(null);
  
  const translateSpeak = (index, text) => {
    if (showTranslationSpeak && translationSpeakIndex === index) {
      setShowTranslationSpeak(false);
      setTranslationText(''); // Clear translation text
    } else {
      setShowTranslationSpeak(true);
      setTranslationText(text); // Set translation text
      setTranslationSpeakIndex(index);
    }
  };
 
  /********** POPUP INSTRUCTION **********/
  const sentenceRef = useRef(null);
  const practiceRef = useRef(null);

  const steps = [
    { ref: sentenceRef, label: translations[selectedLanguage].Expandablesentence, text: translations[selectedLanguage].Clickthissentencetolearn } ,
    { ref: practiceRef, label: translations[selectedLanguage].PracticeLabel, text: translations[selectedLanguage].Practicewhenyouareready}   
  ];

  return (
    <div className="study-page">    
    {topic !== '' ? (
      <div>
      <div className="top-row">
        <button onClick={() => goBack()} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>
        <h3>{translations[selectedLanguage].summary}</h3>
        <BurgerMenuComponent />
      </div>      
      <div  className="summary-body">      
        {/* <div style={{marginLeft:'40px'}} ref={sentenceRef}></div> */}
        {sentencesForStudy.map((entry, index) => (
          <div key={index} className={`summary-sentences ${showTranslationSpeak && translationSpeakIndex === index ? 'summary-active' : ''}`}>
            <p  ref={sentenceRef} onClick={() => translateSpeak(index, getTranslatedSentence(entry, selectedLanguage))}>
              {entry.fr}
            </p>
            {showTranslationSpeak && translationSpeakIndex === index && (
              <div>
                <div className="buttons-in-summary">
                  <div>
                    <button className="speaker-button-summary" onClick={() => playPause(frToAudio(entry.fr, level, englishTopic))}>
                      <img src={isPlaying && currentAudioFile === frToAudio(entry.fr, level, englishTopic) ? "/Icons/pause-summary.svg" : "/Icons/listen-summary.svg"} alt={isPlaying && currentAudioFile === frToAudio(entry.fr, level, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Listen} />
                    </button>
                    <button className="slowdown-button-summary" onClick={() => slowerPause(frToAudio(entry.fr, level, englishTopic))}>
                      <img src={isPlayingSlower && currentAudioFile === frToAudio(entry.fr, level, englishTopic) ? "/Icons/pause-summary.svg" : "/Icons/turtle-summary.svg"} alt={isPlayingSlower && currentAudioFile === frToAudio(entry.fr, level, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Slow} />
                    </button> 
                  </div>                 
                  <button style={{transform: 'translateY(-5px)'}} className="next-button" onClick={() => navigateToStudySentence(index)}>
                    <b>{translations[selectedLanguage].study}</b>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <img src={"/Icons/arrow-right-aqua.svg"} alt={translations[selectedLanguage].next} className="arrow-right" />
                  </button>  
                </div>
                  {isPlayingSlower && currentAudioFile === frToAudio(entry.fr, level, englishTopic) && <PlaybackRateControl />}
                  {englishTopic !== 'Number Exercises' && englishTopic !== 'Number' && englishTopic !== 'Spelling Exercises' && englishTopic !== 'Spelling' && <hr/>}                  
                  <p dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} style={{color:'#E07F9C'}}>{translationText}</p>                  
              </div>
            )}
          </div>
        ))}
      </div>

      <button ref={practiceRef} style={{margin:'-30px auto 100px auto'}} className="practice-button" onClick={goToPractice}><b>{translations[selectedLanguage].PRACTICE}</b></button>
            
      <BottomMenuComponent steps={steps}/>
      </div>      
        ) : (
          <button className="topic-option" onClick={() => navigate('/')}>{translations[selectedLanguage].PleaseChooseALevel}</button>        
        )} 
    </div>
  );
};

export default SummaryPage;